/* Font import */
@import url("https://fonts.googleapis.com/css?family=Raleway:200,500|Roboto+Mono:300i");

* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  padding: 0;
  margin: 0;

  background: var(--iah-light);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
}

.caption-title {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 2em;
}

.caption-description {
  font-family: "Raleway";
  font-weight: 200;
  font-size: 1em;
}

.iah_text_black {
  font-family: "Roboto Mono";
  font-weight: 500;
  font-size: 3em;
  color: var(--iah-grey-dark);
}

.iah_li {
  display: block;
  padding: 4px 10px 4px 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  background: var(--iah-grey-dark);
  border-radius: 5px;
}

.iah_links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

.iah_links,
.iah_link {
  text-decoration: none;
  list-style-type: none;
  font-family: "Roboto Mono";
  font-style: italic;
  font-weight: 300;
  font-size: 1em;
  color: var(--iah-light);
}

.iah_svg {
  display: block;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

@media (min-width: 600px) {
  .iah_links {
    height: 200px;
    flex-direction: column;
    padding: 10px;
  }
}

/* .sidebar {
   padding: 10px 10px 10px 30px;
   } */

/* .content {
   display: grid;
   
   margin: 0;
   padding: 30px;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   grid-auto-rows: 200px;
   grid-auto-flow: dense;
   grid-gap: 10px;

   background: var(--iah_light);
   box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
   } */

/* .image:nth-child(4) {
   grid-row: span 3;
   }

   .image:nth-child(5) {
   grid-row-start: span 2;
   grid-row-end: auto;
   }

   .image:nth-child(6) {
   grid-column-start: span 2;
   grid-column-end: auto;
   grid-row-start: span 2;
   grid-row-end: auto;
   }
   .image:nth-child(3n + 0) {
   grid-column: span 2;
   grid-column-end: auto;
   grid-row: span 2;
   grid-row-end: auto;
   } */

/* span {
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 3em;
   } */

/* li {
   width: 100%;

   list-style-type: none;
   position: relative;
   }

   li:nth-child(4) {
   grid-row: span 3;
   }

   li:nth-child(5) {
   grid-row-start: span 2;
   grid-row-end: auto;
   }

   li:nth-child(6) {
   grid-column-start: span 2;
   grid-column-end: auto;
   grid-row-start: span 2;
   grid-row-end: auto;
   }
   li:nth-child(3n + 0) {
   grid-column: span 2;
   grid-column-end: auto;
   grid-row: span 2;
   grid-row-end: auto;
   } */
